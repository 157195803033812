<template>
  <Modal
    v-model="modal"
    sticky
    scrollable
    :mask-closable="true"
    width="60%"
    @on-cancel="handleCancel"
    class="custom-modal-footer-block"
  >
    <template #header>
      <p class="title" v-if="!isEdit">添加用户</p>
      <p class="title" v-if="isEdit">修改用户</p>
      <div class="modal-close" @click="handleCancel">关闭 <span>ESC</span></div>
    </template>
    <div class="form">
      <Form
        ref="formValidate"
        :rules="rules"
        :model="editObj"
        label-position="top"
      >
        <FormItem label="用户名" prop="username">
          <Input v-model="editObj.username" placeholder="请输入" />
        </FormItem>
        <FormItem label="用户邮箱" prop="account">
          <Input
            v-model="editObj.account"
            placeholder="请输入"
            :disabled="isEdit"
          />
        </FormItem>
        <FormItem label="角色" prop="role">
          <Select placeholder="选择角色" v-model="editObj.role">
            <Option value="general">普通用户</Option>
            <Option value="admin">管理员</Option>
          </Select>
        </FormItem>
        <FormItem label="机构" prop="org">
          <Select
            placeholder="选择机构"
            v-model="editObj.org"
            filterable
            clearable
          >
            <Option
              :label="item.orgName"
              :value="item.orgName"
              v-for="item in orgList"
              :key="item.id"
            ></Option>
          </Select>
        </FormItem>
        <!-- <FormItem label="用户IP">
          <Input v-model="editObj.bindIp" placeholder="请输入" />
        </FormItem> -->
      </Form>
    </div>
    <template #footer>
      <div class="btn btn-default" @click="submit" v-if="!isEdit">确认</div>
      <div class="btn btn-default" @click="handleEdit" v-if="isEdit">修改</div>
    </template>
  </Modal>
</template> 

<script setup>
import { Modal, Message } from "view-ui-plus";
import { ref, inject, onMounted,onBeforeUnmount } from "vue";
import qs from "qs";
import bus from "@/utils/bus";
let axios = inject("axios");
let modal = ref(false);
const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });

let reg =
  /((?:(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))))/;
const validateIP = (rule, value, callback) => {
  if (value == "") {
    callback(new Error("请输入IP"));
  } else {
    if (!reg.test(value)) {
      callback(new Error("IP不符合规则"));
    } else {
      callback();
    }
  }
};

let formValidate = ref(null);
let rules = {
  username: [
    {
      required: true,
      message: "用户名不能为空",
      trigger: "blur",
    },
  ],
  account: [
    {
      required: true,
      message: "用户邮箱不能为空",
      trigger: "blur",
    },
    {
      type: "email",
      message: "邮箱格式错误",
      trigger: "blur",
    },
  ],
  org: [
    {
      required: true,
      message: "机构不能为空",
      trigger: "change",
    },
  ],
  role: [
    {
      required: true,
      message: "角色不能为空",
      trigger: "change",
    },
  ],
  bindIp: [
    {
      required: true,
      validator: validateIP,
      trigger: "blur",
    },
  ],
};


let isEdit = ref(false); //false为新增，true为修改
let editObj = ref({
  username: "",
  account: "",
  org: "",
  role: "",
  // bindIp:""
}); //修改的数据
//修改用户
const handleEdit = () => {
  let url = `/setting/user/edit`;
  let params = {
    name: editObj.value.username,
    account: editObj.value.account,
    orgName: editObj.value.org,
    role: editObj.value.role,
    // bindIp:editObj.value.bindIp,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success({
        background: true,
        content: "用户信息修改成功",
      });
      editObj.value = {
        username: "",
        account: "",
        org: "",
        role: "",
      };
    } else {
      Message.error({
        background: true,
        content: res.data.message,
        duration: 3,
      });
    }
    formValidate.value.resetFields();
    editObj.value = {
      username: "",
      account: "",
      org: "",
    };
    isEdit.value = false;
    modal.value = false;
    bus.emit("searchData");
  });
};
// 添加用户
const submit = () => {
  formValidate.value.validate((valid) => {
    if (valid) {
      let url = `/setting/user/create`;
      let params = {
        username: editObj.value.username,
        account: editObj.value.account,
        org: editObj.value.org,
        role: editObj.value.role,
        bindIp:editObj.value.bindIp,
      };
      axios
        .post(url, params, {
          headers: {
            "content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Message.success({
              background: true,
              content: "用户添加成功",
            });
          } else {
            Message.error({
              background: true,
              content: res.data.message,
              duration: 3,
            });
          }
          formValidate.value.resetFields();
          editObj.value = {
            username: "",
            account: "",
            org: "",
            role: "",
          };
          isEdit.value = false;
          modal.value = false;
          //通知列表重新查询
          bus.emit("searchData");
          //通知头部重新查找禁用和启用总个数
          bus.emit("noticeCount");
        });
    } else {
      // Modal.error({
      //   title: "提醒",
      //   content: "信息填写不完整，请检查后再提交",
      // });
    }
  });
};

//取消
const handleCancel = () => {
  modal.value = false;
  isEdit.value = false;
  formValidate.value.resetFields();
  editObj.value = {
    username: "",
    account: "",
    org: "",
    role: "",
    bindIp:""
  };
};
let loading = ref(false);
let orgList = ref([]);
const getOrgList = (query) => {
  let url = `/setting/org/list`;
  let params = {
    pageOffset: 1,
    pageSize: 500,
    orgName: query,
  };
  loading.value = true;
  axios.get(url, { params }).then((res) => {
    loading.value = false;
    if (res.data.code == 200) {
      let data = res.data.data;
      orgList.value = data.content;
    } else {
      orgList.value = [];
    }
  });
};

onMounted(() => {
  //获取修改来的数据
  bus.on("editId", (row) => {
    isEdit.value = true;
    editObj.value = { ...row };
  });
  getOrgList("");
});
onBeforeUnmount(()=>{
  bus.all.delete('editId');
})
</script>

<style lang="scss" scoped>
</style>