<template>
    <div class="content-wrap">
      <Header @showNav="handleNav" />
      <unComplete v-if="tab == 0"/>
      <Complete v-if="tab == 1"/>
      <Footer/>
    </div>
  </template>
  
  <script setup>
  import { ref,inject,provide,onMounted } from "vue";
  import unComplete from "./opening.vue";
  import Complete from "./forbidden.vue";
  import Header from "../components/userHeader.vue";
  import Footer from "@/components/footer.vue"
  let tab = ref(0);
  function handleNav(val) {
    tab.value = val;
  }
  
  
  
  </script>
  
  <style lang="scss" scoped>
   @import'../index.scss';
  </style>