<template>
  <div class="search">
    <ul>
      <li>
        <!-- <Input placeholder="输入姓名" v-model.trim="name" class="width-200" /> -->
        <AutoComplete
          v-model="name"
          :data="nameList"
          @on-search="searchName"
          placeholder="请输入名称"
          class="width-300"
        >
          <Option
            v-for="item in nameList"
            :value="item.username"
            :key="item"
            :label="item.username"
          >
            {{ item.username }}({{ item.account }})</Option
          >
        </AutoComplete>
      </li>
      <li>
        <Input
          placeholder="输入邮箱"
          v-model.trim="email"
          class="width-300"
          @on-enter="handleSearch"
        />
      </li>
      <li>
        <Select
          placeholder="选择机构"
          class="width-400"
          clearable
          @on-change="changeOrg"
        >
          <Option
            :label="item.orgName"
            :value="item.orgName"
            v-for="item in orgList"
            :key="item.id"
          ></Option>
        </Select>
      </li>
      <li>
        <div class="btn search-btn flex-center-center" @click="handleSearch">
          <div class="img"></div>
        </div>
        <div
          class="btn reset-btn flex-center-center"
          @click="handleReset"
          v-if="name != '' || email != '' || org != ''"
        >
          <div class="img"></div>
        </div>
      </li>
    </ul>
    <div>
      <div class="static">
        <span
          >共 <span class="bold">{{ total }}</span
          >个</span
        >
      </div>
      <div class="btn btn-default" @click="openModal">添加用户</div>
    </div>
  </div>
  <div class="table" v-if="total > 0">
    <Table :columns="columns" :data="tableData" border>
      <template #dataName="{ row }">
        <!-- <Tooltip :content="row.dataName" placement="top" theme="light">
          <span class="name">{{row.dataName}}</span>
          </Tooltip> -->
        <span class="name">{{ row.dataName }}</span>
      </template>
      <template #status="{ row }">
        <span v-if="row.state == 0" class="activation">未激活</span>
        <span v-if="row.state == 2" class="nothrough">已禁用</span>
        <span v-if="row.state == 1" class="through">已启用</span>
      </template>
      <template #dataSize="{ row }">
        <span>{{ row.dataSize }}GB</span>
      </template>
      <template #type="{ row }">
        <span v-if="row.first">{{ row.first }}></span>
        <span v-if="row.second">{{ row.second }}></span>
        <span v-if="row.third">{{ row.third }}</span>
      </template>
      <template #action="{ row }">
        <div class="flex">
          <div class="edit action-btn" @click="handleEdit(row)">
            <div class="img"></div>
          </div>
          <div class="unban action-btn" @click="handleUnban(row)">
            <div class="img"></div>
          </div>
          <div class="del action-btn" @click="handleDel(row)">
            <div class="img"></div>
          </div>
        </div>
      </template>
    </Table>

    <div class="page" v-if="total > 10">
      <Page :total="total" show-elevator show-total @on-change="changePage" :page-size="pageSize"/>
    </div>
  </div>
  <noData v-if="total == 0" />
  <userModal ref="modal" v-if="isOpen" />
  <Modal
    v-model="modal1"
    sticky
    scrollable
    :mask-closable="true"
    class="custom-modal-footer-block"
    @on-cancel="modal1 = false"
  >
    <template #header>
      <p class="title">提醒</p>
      <div class="modal-close" @click="modal1 = false">
        关闭 <span>ESC</span>
      </div>
    </template>
    <div class="alarm-info">你确定要删除吗？</div>
    <template #footer>
      <div class="btn btn-default" @click="confirm">确认</div>
    </template>
  </Modal>
</template>
  
  <script setup>
import { ref, reactive, inject, onMounted, onBeforeUnmount, nextTick } from "vue";
import { Message } from "view-ui-plus";
import { useRouter } from "vue-router";
import bus from "@/utils/bus.js";
import { Modal } from "view-ui-plus";
import qs from "qs";
import noData from "@/components/noData.vue";
import userModal from "../components/userModal.vue";
let modal = ref(null);
let modal1 = ref(false);
let name = ref("");
let email = ref("");
let org = ref("");
let loading = ref(false);
let dataTypeList = ref([]);
let columns = [
  {
    title: "用户",
    key: "username",
    width: 110,
    resizable: true,
  },
  {
    title: "邮箱",
    key: "account",
    width: 150,
    resizable: true,
  },
  {
    title: "状态",
    slot: "status",
    align: "center",
    width: 130,
    resizable: true,
  },
  {
    title: "机构",
    key: "org",
    minWidth: 180,
    resizable: true,
  },
  // {
  //   title: "IP",
  //   key: "bindIp",
  //   minWidth: 120,
  //   resizable: true,
  // },
  {
    title: "角色",
    key: "roleName",
    // align: "center",
    width: 120,
    resizable: true,
  },

  {
    title: "创建时间↑",
    key: "registrationTime",
    // align: "center",
    minWidth: 150,
    resizable: true,
  },
  {
    title: "操作",
    slot: "action",
    // align: "center",
    width: 130,
    // fixed:"right",
    resizable: true,
  },
];
let tableData = ref([]);
let page = ref(1);
let pageSize = ref(10);
let total = ref(0);
let selection = ref([]);
let router = useRouter();

function changePage(val) {
  page.value = val;
  getTableData();
}
let axios = inject("axios");
const getTableData = () => {
  let url = `/setting/user/list/enable`;
  let params = {
    pageOffset: page.value,
    pageSize: pageSize.value,
    username: name.value,
    email: email.value,
    org: org.value,
  };

  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      tableData.value = data.content.map((item) => {
        return {
          ...item,
          roleName: item.role == "admin" ? "管理员" : "普通用户",
        };
      });
      total.value = data.count;
      //通知父组件总个数
      // bus.emit("unCount", total.value);
    }
  });
};

const handleSearch = () => {
  page.value = 1;
  getTableData();
};

//编辑文件
const handleEdit = (row) => {
  openModal();
  nextTick(() => {
    bus.emit("editId", row);
  });
};
//禁用
const handleUnban = (row) => {
  let url = `/setting/user/disable`;
  let params = {
    userAccount: row.account,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success("禁用成功");
      handleSearch();
      //通知头部重新查找禁用和启用总个数
      bus.emit("noticeCount");
    } else {
      Message.error({
        background: true,
        content: res.data.message,
      });
    }
  });
};

let curData = ref({});
//删除文件
const handleDel = (row) => {
  curData.value = row;
  modal1.value = true;
};

// 确定删除
const confirm = () => {
  let row = curData.value;
  let url = `/setting/user/remove`;
  let params = {
    account: row.account,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success({
        background: true,
        content: "删除成功",
      });
      modal1.value = false;
      handleSearch();
    } else {
      Message.error({
        background: true,
        content: res.data.message,
      });
    }
  });
};
const handleReset = () => {
  name.value = "";
  email.value = "";
  org.value = "";
  handleSearch();
};

let isOpen = ref(false);
//打开modal
const openModal = () => {
  isOpen.value = true;
  nextTick(() => {
    modal.value.modify();
  });
};

let nameList = ref([]);
const searchName = (value) => {
  let url = `/setting/user/list/enable`;
  let params = {
    pageOffset: 1,
    pageSize: 500,
    username: value,
    email: "",
    org: "",
  };
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      nameList.value = res.data.data.content;
    } else {
      nameList.value = [];
    }
  });
};

//获取机构列表
let orgList = ref([]);
const getOrgList = (query) => {
  let url = `/setting/org/list`;
  let params = {
    pageOffset: 1,
    pageSize: 500,
    orgName: query,
  };

  // loading.value = true;
  axios.get(url, { params }).then((res) => {
    // loading.value = false;
    if (res.data.code == 200) {
      let data = res.data.data;
      orgList.value = data.content;
    } else {
      orgList.value = [];
    }
  });
};
const changeOrg = (val) => {
  org.value = val;
};

onMounted(() => {
  bus.on("searchData", () => {
    handleSearch();
  });
  handleSearch();
  getOrgList("");
});
onBeforeUnmount(()=>{
  bus.all.delete('searchData');
})
</script>
  
<style lang="scss" scoped>
@import "@/views/dataFilling/index.scss";
@import "../index.scss";
.edit .img {
  width: 14px;
  height: 14px;
  background: url(../../../assets/img/编辑-默认.png) no-repeat;
  background-size: cover;
}
.edit:hover .img {
  width: 14px;
  height: 14px;
  background: url(../../../assets/img/编辑-选中.png) no-repeat;
  background-size: cover;
}
.del .img {
  width: 14px;
  height: 14px;
  background: url(../../../assets/img/删除-默认.png) no-repeat;
  background-size: cover;
}

.del:hover .img {
  width: 14px;
  height: 14px;
  background: url(../../../assets/img/删除-选中.png) no-repeat;
  background-size: cover;
}
.unban .img {
  width: 14px;
  height: 14px;
  background: url(../../../assets/img/停用-默认.png) no-repeat;
  background-size: cover;
}
.unban:hover .img {
  width: 14px;
  height: 14px;
  background: url(../../../assets/img/停用-选中.png) no-repeat;
  background-size: cover;
}
</style>